<script setup lang="ts">
definePageMeta({
	layout: 'auth',
});

useSeoMeta({
	title: 'Login',
});
</script>

<template>
	<AuthLoginForm />
</template>

<style scoped>
	.shadow-logo {
		-webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
		filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 1));
	}

	.dark {
		.shadow-logo {
		-webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
		filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.1));
	}
	}
</style>
